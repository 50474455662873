// Modules
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import Breadcrumbs from "../breadcrumbs";
import CustomGatsbyImage from "../gatsbyImage";

// Styles
import * as styles from "./hero.module.scss";

const RedesignedHero = (props) => {
  const {
    breadcrumbs,
    className,
    contentClassName,
    imageClassName,
    image,
    subTitle,
    title,
  } = props;
  const wrapperClassName = cx(styles.heroWrapper);
  const contentClass = cx(styles.content, contentClassName);

  return (
    <div className={wrapperClassName}>
      <CustomGatsbyImage
        alt="hero-back"
        imgClassName={imageClassName}
        img={image?.asset}
      />
      <div className={cx(styles.contentWrapper, className)}>
        <div />
        <div className={contentClass}>
          <div className={styles.whiteBlackTop} />
          <div className={styles.whiteBlackBottom} />
          {breadcrumbs && (
            <Breadcrumbs links={breadcrumbs} className={styles.breadcrumbs} />
          )}

          <h1>
            <mark>{title}</mark>
          </h1>
          {subTitle && <p>{subTitle}</p>}
        </div>
        <div />
      </div>
    </div>
  );
};

RedesignedHero.propTypes = {
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  imageClassName: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
};

RedesignedHero.defaultProps = {
  breadcrumbs: false,
  className: "",
  contentClassName: "",
  imageClassName: "",
  subTitle: "",
  title: "",
};

export default RedesignedHero;
